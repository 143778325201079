<template>
  <div class="add-book">
    <BookForm :useBook="book" v-if="book" isAdmin />
  </div>
</template>

<script>
import BookForm from "@/components/book-form/book-form";
import bookHelper from "@/helpers/book";

export default {
  name: "add-book",
  components: {
    BookForm
  },
  data() {
    return {
      book: null
    };
  },
  mounted() {
    this.get();
  },
  computed: {
    id() {
      return this.$route.params.id;
    }
  },
  methods: {
    async get() {
      this.ebpLoader(true);

      try {
        const res = await this.$store.dispatch("books/show", this.id);
        this.book = bookHelper.convertFromAPIFormat(res.data);
      } catch (err) {
        console.error(err);
        this.$router.push("/admin/books");
      }

      this.ebpLoader(false);
    }
  }
};
</script>

<style></style>
